import currency from 'currency.js'
import {defineStore} from 'pinia'

export const useCartStore = defineStore('cart', () => {
  /**
   * @type {Array<{
   *   event: any,
   *   date: string,
   *   session: any,
   *   tickets: Array<{}>
   *   total: number
   * }>}
   */
  const cartItems = ref([])

  const cartItemCount = computed(() => {
    return cartItems.value.length
  })

  const cartTicketsCount = computed(() => {
    let count = 0

    cartItems.value.forEach((item) => {
      count += item.tickets.length
    })

    return count
  })

  const cartTotal = computed(() => {
    let total = 0

    cartItems.value.forEach((item) => {
      total = currency(total).add(item.total)
    })

    return total
  })

  const showCartDropdown = ref(false)

  /**
   * Adds an item to the cart.
   *
   * @param {Object} item - The item to be added to the cart.
   * @param {Object} item.event - The event object.
   * @param {string} item.date - The date of the event.
   * @param {Object} item.session - The session object.
   * @param {Array} item.tickets - The tickets array.
   * @param {number} item.total - The total price of the tickets.
   * @returns {void}
   */
  const add = (item) => {
    const existingItemIndex = cartItems.value.findIndex((cartItem) => cartItem.event.id === item.event.id)
    if (existingItemIndex !== -1) {
      cartItems.value[existingItemIndex] = item
    } else {
      cartItems.value.push(item)
    }
    showCartDropdown.value = true
  }

  const remove = (id) => {
    const index = cartItems.value.findIndex((item) => item.id === id)
    cartItems.value.splice(index, 1)
  }

  const clear = () => (cartItems.value = [])

  const orderGroupId = ref(null)
  const setOrderGroupId = (id) => (orderGroupId.value = id)

  const currencySymbol = computed(() => {
    return cartItems.value[0]?.event?.currency_symbol
  })

  const checkoutCart = () => {
    const router = useRouter()
    const localePath = useLocalePath()
    router.push(localePath({name: 'checkout-payment'}))
  }

  const resetStore = () => {
    cartItems.value = []
    orderGroupId.value = null
  }

  return {
    cartItems,
    cartItemCount,
    cartTicketsCount,
    cartTotal,

    showCartDropdown,

    add,
    remove,
    clear,

    orderGroupId,
    setOrderGroupId,

    currencySymbol,

    checkoutCart,

    resetStore,
  }
})
